'use client';

import React from "react";
import Image from 'next/image';
import Link from 'next/link';


export default function NotFound() {

    return(
        <>
        

        <div>
            <div>

                        <div>
                            <Image
                                src="/img/error.svg"
                                alt="Страница не найдена"
                                width={125} 
                                height={125} 
                            />
                        </div>

                        <p>Страница не найдена, попробуйте начать 
                            <Link href={"/"} className="errorLink">с главной</Link>
                        </p>
            </div>
        </div>



        </>
    )
}